import { AccountInfo, InteractionStatus, PublicClientApplication } from '@azure/msal-browser';
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Box, Breadcrumbs, Button, CssVarsProvider, GlobalStyles, LinearProgress, Link, Modal, ModalDialog, Typography } from '@mui/joy';
import CssBaseline from '@mui/joy/CssBaseline';
import { useEffect, useRef, useState } from 'react';
// import { identityTheme } from './components/Theme';
import { datadogRum } from '@datadog/browser-rum';
import { extendTheme } from '@mui/joy/styles';
import { useHash } from 'react-use';
import { env } from './env';

import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';

import Sidebar from './components/Sidebar';
import TicketTable from './components/TicketTable';
import Header from './components/Header';
import { Core } from './utilities/core';
import React from 'react';
import NewTicket from './components/NewTicket';

datadogRum.init({
  applicationId: '688723b9-66ac-4aa3-8ef1-c171649e17bb',
  clientToken: 'pub6cdfac2885c0939da877ebaa0d07413a',
  site: 'datadoghq.eu',
  service: 'ef-helpdesk',
  env: env.NODE_ENV || "development",
  version: env.APP_VERSION,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  trackViewsManually: true,
  defaultPrivacyLevel: 'allow',
  allowedTracingUrls: ["https://integration-hub.ef.com"]
});

const theme = extendTheme({
  fontFamily: {
    body: "EFCircularWeb Book Web",
    display: "EFCircularWeb Medium Web"
  },
  colorSchemes: {
    dark: {
      palette: {
        neutral: {
          solidBg: "#003C64",
          softBg: "#0e1923",
          softDisabledBg: "#0e1923",
          softDisabledColor: "#FFFFFF"
        },
        background: {
          body: "#15202B",
          surface: "#0e1923",
          backdrop: "#15202B00",
          level1: "#15202B",
          popup: "#0e1923",
        },
        danger: {
          300: '#8C1D18',
          400: '#913b37',
          500: '#91312d',
          600: '#601410',
          700: '#8C1D18',
          800: '#69130f',
        },
        primary: {
          500: '#003C64',
          600: '#1B62B5',
        },
      },
    },
  },
});

interface IdentityApplicationProps {
  instance: PublicClientApplication;
}
const IdentityApplication = ({ instance }: IdentityApplicationProps) => {
  const { inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [selected, setSelected] = React.useState<string>();
  const [hash, setHash] = useHash();

  useEffect(() => {
    console.log(hash)
    if (hash) {
      setSelected(hash);
    } else {
      setHash("tickets");
    }
  }, [hash]);

  const [account, setAccount] = useState<AccountInfo>();
  useEffect(() => {
    if (instance.getAllAccounts().length > 0) {
      setAccount(instance.getAllAccounts()[0]);
    } else if (!isAuthenticated && inProgress === InteractionStatus.None) {
      instance.clearCache().then(() => {
        instance.loginRedirect();
      });
    }
  }, [isAuthenticated, inProgress, instance]);

  useEffect(() => {
    if (account) {
      // This log is intentional.
      console.log(JSON.stringify(
        {
          env: env.NODE_ENV || "development",
          version: env.APP_VERSION,
          host: window.location.hostname,
          user_id: account.username,
        }
      ))
      datadogRum.setUser({
        id: account.username,
        name: account.name,
      });
    }
  }, [account])

  return (
    <CssVarsProvider
      disableTransitionOnChange
      disableNestedContext
      defaultMode="system"
      modeStorageKey="displayMode"
      theme={theme}>
      <CssBaseline />
      <GlobalStyles
        styles={(theme) => ({
          '[data-feather], .feather': {
            color: `var(--Icon-color, ${theme.vars.palette.text.icon})`,
            margin: 'var(--Icon-margin)',
            fontSize: `var(--Icon-fontSize, ${theme.vars.fontSize.xl})`,
            width: '1em',
            height: '1em',
          },
        })}
      />
      <CssBaseline />
      <AuthenticatedTemplate>
        <Box sx={{ display: 'flex', minHeight: '100dvh' }}>
          <Header />
          <Sidebar account={account} selected={selected} setSelected={setSelected} />
          <Box
            component="main"
            className="MainContent"
            sx={{
              px: { xs: 2, md: 6 },
              pt: {
                xs: 'calc(12px + var(--Header-height))',
                sm: 'calc(12px + var(--Header-height))',
                md: 3,
              },
              pb: { xs: 2, sm: 2, md: 3 },
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              minWidth: 0,
              height: '100dvh',
              gap: 1,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Breadcrumbs
                size="sm"
                aria-label="breadcrumbs"
                separator={<ChevronRightRoundedIcon />}
                sx={{ pl: 0 }}
              >
                <Link
                  underline="none"
                  color="neutral"
                  href="#some-link"
                  aria-label="Home"
                >
                  <HomeRoundedIcon />
                </Link>
                <Link
                  underline="hover"
                  color="neutral"
                  href="#some-link"
                  sx={{ fontSize: 12, fontWeight: 500 }}
                >
                  {hash === "tickets" ? "My Tickets" : "New Ticket"}
                </Link>
              </Breadcrumbs>
            </Box>
            <Box
              sx={{
                display: 'flex',
                mb: 1,
                gap: 1,
                flexDirection: { xs: 'column', sm: 'row' },
                alignItems: { xs: 'start', sm: 'center' },
                flexWrap: 'wrap',
                justifyContent: 'space-between',
              }}
            >
              <Typography level="h2" component="h1">
                {hash === "#tickets" ? "My Tickets" : "New Ticket"}
              </Typography>
            </Box>
            {hash === "#tickets" && <TicketTable account={account} instance={instance} />}
            {hash === "#new" && <NewTicket account={account} instance={instance} />}
          </Box>
        </Box>
      </AuthenticatedTemplate>
    </CssVarsProvider>
  )
}

interface AppProps {
  msalInstance: PublicClientApplication;
}

export default function App({ msalInstance }: AppProps) {
  return (
    <MsalProvider instance={msalInstance}>
      <IdentityApplication instance={msalInstance} />
    </MsalProvider>
  )
}