import * as React from 'react';
import { ColorPaletteProp } from '@mui/joy/styles';
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Chip from '@mui/joy/Chip';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Link from '@mui/joy/Link';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import ModalClose from '@mui/joy/ModalClose';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Checkbox from '@mui/joy/Checkbox';
import IconButton, { iconButtonClasses } from '@mui/joy/IconButton';
import Typography, { typographyClasses } from '@mui/joy/Typography';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import Dropdown from '@mui/joy/Dropdown';

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import BlockIcon from '@mui/icons-material/Block';
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import { AccountInfo } from '@azure/msal-browser';
import { Card, CardActions, CardOverflow, DialogContent, DialogTitle, FormHelperText, Grid, LinearProgress, Step, stepClasses, StepIndicator, stepIndicatorClasses, Stepper, Textarea } from '@mui/joy';
import { Core } from '../utilities/core';
import ForumIcon from '@mui/icons-material/Forum';
import ListIcon from '@mui/icons-material/List';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// function RowMenu() {
//   return (
//     <Dropdown>
//       <MenuButton
//         slots={{ root: IconButton }}
//         slotProps={{ root: { variant: 'plain', color: 'neutral', size: 'sm' } }}
//       >
//         <MoreHorizRoundedIcon />
//       </MenuButton>
//       <Menu size="sm" sx={{ minWidth: 140 }}>
//         <MenuItem>Edit</MenuItem>
//         <MenuItem>Rename</MenuItem>
//         <MenuItem>Move</MenuItem>
//         <Divider />
//         <MenuItem color="danger">Delete</MenuItem>
//       </Menu>
//     </Dropdown>
//   );
// }

interface SalesforceQueryResponse<T> {
  totalSize: number;
  done: boolean;
  records: T[];
}

interface CaseAttributes {
  type: "Case";
  url: string;
}

interface CaseItem {
  attributes: CaseAttributes;
  Id: string;
  CaseNumber: string;
  Subject: string;
  Description: string | null;
  Status: string;
  LastModifiedDate: string;
  Category__c: string | null;
  Agent__c: string | null;
  Agents_First_Name__c: string | null;
  Priority: string;
  Case_Description__c: string | null;
  Module__c: string | null;
  Incident_type__c: string | null;
}

interface CaseFeedAttributes {
  type: "CaseFeed";
  url: string;
}

interface CaseFeedRecord {
  attributes: CaseFeedAttributes;
  Body: string | null;
  CreatedById: string;
  LastModifiedDate: string;
  Type: "TextPost" | "CreateRecordEvent" | "TrackedChange";
}

function sortCaseFeedItemsByDate(items: any[]): any[] {
  return items.slice().sort((a, b) => {
    const dateA = new Date(a.LastModifiedDate);
    const dateB = new Date(b.LastModifiedDate);

    // Handle invalid dates (optional, but recommended)
    if (isNaN(dateA.getTime()) && isNaN(dateB.getTime())) {
      return 0; // Both are invalid, consider them equal.
    } else if (isNaN(dateA.getTime())) {
      return 1; // dateA is invalid, dateB is valid, dateB comes first.
    } else if (isNaN(dateB.getTime())) {
      return -1; // dateB is invalid, dateA is valid, dateA comes first.
    }

    return dateB.getTime() - dateA.getTime(); // Sort in descending order (latest first)
  });
}

export default function TicketTable({ account, instance }: any) {
  const [order, setOrder] = React.useState<Order>('desc');
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [open, setOpen] = React.useState(false);
  const [rows, setRows] = React.useState<CaseItem[] | undefined>();
  const [feedItems, setFeedItems] = React.useState<CaseFeedRecord[] | undefined>();
  const [core, setCore] = React.useState<Core | undefined>(undefined);

  const [modalId, setModalId] = React.useState<string | undefined>();
  const [showModal, setShowModal] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (account && instance) {
      new Core().initialiseCore(instance, account).then(core => {
        setCore(core);
      });
    }
  }, [account, instance]);

  React.useEffect(() => {
    if (modalId && core) {
      core.invokeIntegrationHubRequest('GET', `/salesforce/ticket/feed?ticketNumber=${modalId}`).then(resp => {
        console.log(((resp.body as any)[0]?.Feeds.records));
        try {
          setFeedItems(sortCaseFeedItemsByDate((resp.body as any)[0]?.Feeds.records as any as CaseFeedRecord[]).reverse());
        } catch (err) {
          console.log(err);
        }
      })
      setShowModal(true);
    }
  }, [modalId]);

  React.useEffect(() => {
    if (!showModal) {
      setModalId(undefined);
    }
  }, [showModal]);


  React.useEffect(() => {
    if (account && core) {
      core.invokeIntegrationHubRequest('GET', `/salesforce/tickets?upn=${(account as AccountInfo).username}`).then(resp => {
        // console.log((resp.body as any as SalesforceQueryResponse<SalesforceCaseRecord>));
        setRows((resp.body as any as CaseItem[]));
      })
      // fetch(`https://rtbh2wzg97.execute-api.eu-west-1.amazonaws.com/stg/salesforce/tickets/123?upn=${(account as AccountInfo).username}`, {
      //   method: "GET",
      //   headers: {
      //     "Authorization": `Bearer ${}`
      //   }
      // }).then(resp => {
      //   resp.json().then(data => {
      //     console.log(data);
      //   });
      // }).catch(err => {
      //   console.log(err);
      // });
    }
  }, [account, core]);

  return (
    <React.Fragment>
      <Modal open={showModal} onClose={(evt, reason) => {
        switch (reason) {
          case 'closeClick':
            setFeedItems(undefined);
            setShowModal(false);
            break;
          default:
            setShowModal(true);
            break;
        }
      }}>
        <ModalDialog variant={"soft"} sx={{
          p: 0,
          mx: 0,
          mt: {
            xs: 8,
            sm: 0
          },
          width: {
            xs: "100%",
            sm: "auto"
          },
          minWidth: {
            xs: "100%",
            sm: "50vw"
          },
          overflow: "auto"
        }}>
          <Card>
            <ModalClose variant='solid' color='danger'>Close</ModalClose>
            <Typography level='title-md'>Ticket Details</Typography>
            <Divider></Divider>
            <Box
              key={0}
              sx={{
                padding: 0,
                display: 'grid',
                justifyContent: "space-between",
                gridTemplateColumns: {
                  xs: '100%',
                  sm: '50% 50%',
                  lg: 'repeat(auto-fit, 32%) 32%',
                },
                mb: 2,
                '& > hr': {
                  gridColumn: '1/-1',
                }
              }}
            >
              {showModal &&
                Object.keys(rows?.find(row => row.Id === modalId) || {}).map(key => {
                  if ((rows?.find(row => row.Id === modalId) as any)?.[key] !== null && (key !== "attributes" && (!key.toLowerCase().includes("description")))) {
                    return <Box
                      key={key}
                      sx={{
                        padding: 0
                      }}
                    >
                      <Typography mt={2} level='title-sm'>{key.split("__c").join("").split("_").join(" ")}</Typography>
                      <Input readOnly value={
                        typeof (rows?.find(row => row.Id === modalId) as any)?.[key] === "string" ?
                          decodeURI((rows?.find(row => row.Id === modalId) as any)?.[key]) : ""
                      }></Input>
                    </Box>
                  }
                })
              }
              {/* <FormControl>
                <FormLabel>Ticket Number</FormLabel>
                <Input readOnly placeholder="Placeholder" value={rows?.find(row => row.Id === modalId)?.CaseNumber} />
              </FormControl>

              <FormControl>
                <FormLabel>Subject</FormLabel>
                <Input readOnly placeholder="Placeholder" value={
                  rows?.find(row => row.Id === modalId)?.Subject
                } />
              </FormControl>

              <FormControl>
                <FormLabel>Category</FormLabel>
                <Input readOnly placeholder="Placeholder" value={
                  rows?.find(row => row.Id === modalId)?.Category__c || rows?.find(row => row.Id === modalId)?.Module__c || ""
                } />
              </FormControl>

              <FormControl>
                <FormLabel>Status</FormLabel>
                <Input readOnly placeholder="Placeholder" value={
                  rows?.find(row => row.Id === modalId)?.Status
                } />
              </FormControl>

              <FormControl>
                <FormLabel>Priority</FormLabel>
                <Input readOnly placeholder="Placeholder" value={
                  rows?.find(row => row.Id === modalId)?.Priority
                } />
              </FormControl>

              <FormControl>
                <FormLabel>Last Updated</FormLabel>
                <Input readOnly placeholder="Placeholder" value={
                  rows?.find(row => row.Id === modalId)?.LastModifiedDate ?
                    new Date(rows?.find(row => row.Id === modalId)?.LastModifiedDate || "").toDateString() + " at " + new Date(rows?.find(row => row.Id === modalId)?.LastModifiedDate || "").toTimeString().split("+")[0]
                    : "This ticket has not been updated yet"
                } />
              </FormControl>

              <FormControl>
                <FormLabel>Agent</FormLabel>
                <Input readOnly placeholder="Placeholder" value={
                  rows?.find(row => row.Id === modalId)?.Agents_First_Name__c || "This ticket is currently unassigned."
                } />
              </FormControl> */}
            </Box>
            <Typography level='title-md'>Ticket Feed</Typography>
            <Divider></Divider>
            <Stepper
              orientation="vertical"
              sx={(theme) => ({
                '--Stepper-verticalGap': '2rem',
                '--StepIndicator-size': '2rem',
                '--Step-gap': '1rem',
                '--Step-connectorInset': '0.5rem',
                '--Step-connectorRadius': '1rem',
                '--Step-connectorThickness': '4px',
                '--joy-palette-success-solidBg': 'var(--joy-palette-success-400)',
                [`& .${stepClasses.completed}`]: {
                  '&::after': { bgcolor: 'neutral.solidBg' },
                },
                [`& .${stepClasses.active}`]: {
                  [`& .${stepIndicatorClasses.root}`]: {
                    border: '4px solid',
                    borderColor: '#fff',
                    boxShadow: `0 0 0 1px ${theme.vars.palette.primary[500]}`,
                  },
                },
                [`& .${stepClasses.disabled} *`]: {
                  color: 'neutral.softDisabledColor',
                },
                [`& .${typographyClasses['title-sm']}`]: {
                  textTransform: 'uppercase',
                  letterSpacing: '1px',
                  fontSize: '10px',
                },
              })}
            >
              {
                feedItems ?
                  <Step
                    completed
                    indicator={
                      <StepIndicator variant="solid" color="primary">
                        <ListIcon />
                      </StepIndicator>
                    }
                  >
                    <div>
                      <Typography level="title-sm">Description</Typography>
                      {decodeURI(rows?.find(row => row.Id === modalId)?.Description || rows?.find(row => row.Id === modalId)?.Case_Description__c || "").replace(/<[^>]*>?/gm, '')}
                    </div>
                  </Step>
                  : <LinearProgress></LinearProgress>
              }
              {
                feedItems && feedItems?.filter(item => item.Type === "TextPost").filter(item => item.Body !== null).map(item => {
                  return <Step
                    completed
                    indicator={
                      <StepIndicator variant="solid" color="primary">
                        <ChatBubbleIcon />
                      </StepIndicator>
                    }
                  >
                    <div>
                      {/* <Typography level="title-sm">Message</Typography> */}
                      <Typography level="body-sm" lineHeight={1}>
                        <Chip sx={{
                          px: 2,
                          py: 0.5,
                        }} variant='solid' color='primary'>{(item.Body || "").replace(/<[^>]*>?/gm, '')}</Chip>
                      </Typography>

                    </div>
                  </Step>
                })
              }
            </Stepper>
            <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
              <FormControl sx={{
                mt: 2
              }}>
                <FormLabel>Reply</FormLabel>
                <Textarea disabled content='text' placeholder="This feature is coming soon." />
              </FormControl>
              <CardActions sx={{ alignSelf: 'flex-end', flexFlow: "row", pt: 2 }}>
                <Button disabled>Send</Button>
              </CardActions>
            </CardOverflow>
          </Card>
        </ModalDialog>

      </Modal>
      <Sheet
        className="SearchAndFilters-mobile"
        sx={{ display: { xs: 'flex', sm: 'none' }, my: 1, gap: 1 }}
      >
        <Input
          size="sm"
          placeholder="Search"
          startDecorator={<SearchIcon />}
          sx={{ flexGrow: 1 }}
        />
        <IconButton
          size="sm"
          variant="outlined"
          color="neutral"
          onClick={() => setOpen(true)}
        >
          <FilterAltIcon />
        </IconButton>

        <Modal open={open} onClose={() => setOpen(false)}>
          <ModalDialog aria-labelledby="filter-modal" layout="fullscreen">
            <ModalClose />
            <Typography id="filter-modal" level="h2">
              Filters
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Sheet sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {/* {renderFilters()} */}
              <Button color="primary" onClick={() => setOpen(false)}>
                Submit
              </Button>
            </Sheet>
          </ModalDialog>
        </Modal>
      </Sheet>
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: 'sm',
          py: 2,
          display: "flex",
          flexWrap: 'wrap',
          gap: 1.5,
          '& > *': {
            minWidth: { xs: '120px', md: '160px' },
          },
        }}
      >
        <FormControl sx={{ flex: 1 }} size="sm">
          <FormLabel>Search for order</FormLabel>
          <Input size="sm" placeholder="Search" startDecorator={<SearchIcon />} />
        </FormControl>
        {/* {renderFilters()} */}
      </Box>
      {
        rows !== undefined ? <Sheet
          className="TicketTableContainer"
          variant="outlined"
          sx={{
            display: { xs: 'initial', sm: 'initial' },
            width: '100%',
            borderRadius: 'sm',
            flexShrink: 1,
            overflow: 'auto',
            minHeight: 0,
          }}
        >
          <Table
            aria-labelledby="tableTitle"
            stickyHeader
            hoverRow
            sx={{
              '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
              '--Table-headerUnderlineThickness': '1px',
              '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
              '--TableCell-paddingY': '4px',
              '--TableCell-paddingX': '8px',
            }}
          >
            <thead>
              <tr>
                <th style={{ width: 48, textAlign: 'center', padding: '12px 6px' }}>
                  <Checkbox
                    size="sm"
                    indeterminate={
                      selected.length > 0 && selected.length !== rows.length
                    }
                    checked={selected.length === rows.length}
                    onChange={(event) => {
                      setSelected(
                        event.target.checked ? rows.map((row) => row.Id) : [],
                      );
                    }}
                    color={
                      selected.length > 0 || selected.length === rows.length
                        ? 'primary'
                        : undefined
                    }
                    sx={{ verticalAlign: 'text-bottom' }}
                  />
                </th>
                <th style={{ width: 120, padding: '12px 6px' }}>
                  <Link
                    underline="none"
                    color="primary"
                    component="button"
                    onClick={() => setOrder(order === 'asc' ? 'desc' : 'asc')}
                    endDecorator={<ArrowDropDownIcon />}
                    sx={[
                      {
                        fontWeight: 'lg',
                        '& svg': {
                          transition: '0.2s',
                          transform:
                            order === 'desc' ? 'rotate(0deg)' : 'rotate(180deg)',
                        },
                      },
                      order === 'desc'
                        ? { '& svg': { transform: 'rotate(0deg)' } }
                        : { '& svg': { transform: 'rotate(180deg)' } },
                    ]}
                  >
                    Ticket Number
                  </Link>
                </th>
                <th style={{ width: 140, padding: '12px 6px' }}>Last Updated</th>
                <th style={{ width: 60, padding: '12px 6px' }}>Status</th>
                <th style={{ width: 60, padding: '12px 6px' }}>Priority</th>
                <th style={{ width: 300, padding: '12px 6px' }}>Subject</th>
                {/* <th style={{ width: 100, padding: '12px 6px' }}>Category</th> */}
                <th style={{ width: 100, padding: '12px 6px' }}>Type</th>
              </tr>
            </thead>
            <tbody>
              {[...rows].sort(getComparator(order, 'Id')).map((row) => (
                <tr key={row.Id} style={{ cursor: 'pointer' }} onClick={
                  e => {
                    e.preventDefault();
                    setModalId(row.Id);
                  }
                }>
                  <td style={{ textAlign: 'center', width: 120 }}>
                    <Checkbox
                      size="sm"
                      checked={selected.includes(row.Id)}
                      color={selected.includes(row.Id) ? 'primary' : undefined}
                      onChange={(event) => {
                        setSelected((ids) =>
                          event.target.checked
                            ? ids.concat(row.Id)
                            : ids.filter((itemId) => itemId !== row.Id),
                        );
                      }}
                      slotProps={{ checkbox: { sx: { textAlign: 'left' } } }}
                      sx={{ verticalAlign: 'text-bottom' }}
                    />
                  </td>
                  <td>
                    <Typography level="body-sm"><Chip sx={{
                      px: 2,
                      py: 0.5
                    }} variant='solid'>{row.CaseNumber}</Chip></Typography>
                  </td>
                  <td>
                    <Typography level="body-sm">
                      {row.LastModifiedDate ?
                        new Date(row.LastModifiedDate).toDateString() + " at " + new Date(row.LastModifiedDate).toTimeString().split("+")[0]
                        : "This ticket has not been updated yet"
                      }
                    </Typography>
                  </td>
                  <td>
                    <Typography level="body-sm"><Chip sx={{
                      px: 2,
                      py: 0.5
                    }}
                      variant="solid"
                      startDecorator={
                        {
                          "Resolved": <CheckRoundedIcon />,
                          "Closed": <CheckRoundedIcon />,
                          "In Progress": <AutorenewRoundedIcon />,
                          "Pending": <AutorenewRoundedIcon />,
                          "New": <ConfirmationNumberIcon />,
                          "Open": <ConfirmationNumberIcon />
                        }[row.Status]
                      }
                      color={
                        {
                          "Resolved": 'success',
                          "Closed": 'success',
                          "In Progress": 'warning',
                          "Pending": 'warning',
                          "New": 'primary',
                          "Open": 'primary'
                        }[row.Status] as ColorPaletteProp
                      }
                    >
                      {row.Status}
                    </Chip></Typography>
                  </td>
                  <td>
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                      <Typography level="body-sm">{
                        // Replace here
                        "Medium"
                      }</Typography>
                      {/* <Avatar size="sm">{row.subject}</Avatar> */}
                      {/* <div>
                    <Typography level="body-sm">{row.subject}</Typography>
                      <Typography level="body-sm">{row.subject}</Typography>
                    </div> */}
                    </Box>
                  </td>
                  <td>
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                      <Typography level="body-sm">{row.Subject}</Typography>
                      {/* <Avatar size="sm">{row.subject}</Avatar> */}
                      {/* <div>
                    <Typography level="body-sm">{row.subject}</Typography>
                      <Typography level="body-sm">{row.subject}</Typography>
                    </div> */}
                    </Box>
                  </td>

                  <td>
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                      <Typography level="body-sm">{
                        row.Incident_type__c || "Support Request"
                      }</Typography>
                      {/* <Avatar size="sm">{row.subject}</Avatar> */}
                      {/* <div>
                    <Typography level="body-sm">{row.subject}</Typography>
                      <Typography level="body-sm">{row.subject}</Typography>
                    </div> */}
                    </Box>
                  </td>

                  {/* <td>
                  <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <Link level="body-sm" component="button">
                      Download
                    </Link>
                    <RowMenu />
                  </Box>
                </td> */}
                </tr>
              ))}
            </tbody>
          </Table>
          {
            rows.length > 0 ? "" : <Typography m={2}>Your currently have no active tickets.</Typography>
          }
        </Sheet> : <Box>
          <Typography mb={2} level="body-sm">Loading, please wait...</Typography>
          <LinearProgress variant='plain' />
        </Box>
      }
      {/* <Box
        className="Pagination-laptopUp"
        sx={{
          pt: 2,
          gap: 1,
          [`& .${iconButtonClasses.root}`]: { borderRadius: '50%' },
          display: {
            xs: 'none',
            md: 'flex',
          },
        }}
      >
        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          startDecorator={<KeyboardArrowLeftIcon />}
        >
          Previous
        </Button>

        <Box sx={{ flex: 1 }} />
        {['1', '2', '3', '…', '8', '9', '10'].map((page) => (
          <IconButton
            key={page}
            size="sm"
            variant={Number(page) ? 'outlined' : 'plain'}
            color="neutral"
          >
            {page}
          </IconButton>
        ))}
        <Box sx={{ flex: 1 }} />
        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          endDecorator={<KeyboardArrowRightIcon />}
        >
          Next
        </Button>
      </Box> */}
    </React.Fragment >
  );
}
