import * as React from 'react';
import { ColorPaletteProp } from '@mui/joy/styles';
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Chip from '@mui/joy/Chip';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Link from '@mui/joy/Link';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import ModalClose from '@mui/joy/ModalClose';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Checkbox from '@mui/joy/Checkbox';
import IconButton, { iconButtonClasses } from '@mui/joy/IconButton';
import Typography, { typographyClasses } from '@mui/joy/Typography';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import Dropdown from '@mui/joy/Dropdown';

import ErrorIcon from '@mui/icons-material/Error';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import BlockIcon from '@mui/icons-material/Block';
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import { AccountInfo } from '@azure/msal-browser';
import { Autocomplete, AutocompleteOption, Card, CardActions, CardContent, CardOverflow, DialogContent, DialogTitle, FormHelperText, Grid, LinearProgress, ListItemDecorator, Stack, Step, stepClasses, StepIndicator, stepIndicatorClasses, Stepper, Textarea } from '@mui/joy';
import { Core } from '../utilities/core';
import ForumIcon from '@mui/icons-material/Forum';
import ListIcon from '@mui/icons-material/List';
import InfoIcon from '@mui/icons-material/Info';
import FileUpload from './FileUpload';
import DropZone from './FileUpload';
import SendIcon from '@mui/icons-material/Send';
import { Add } from '@mui/icons-material';

import { Buffer } from 'buffer';
window.Buffer = Buffer;

interface Validation {
  pattern?: string;
  minLength?: number;
  maxLength?: number;
}

interface Salesforce {
  fieldName: string;
}

interface DynamicOptions {
  mappingFrom: string;
  mapping: Record<string, string[]>;
}

interface VisibilityCondition {
  defaultVisibility?: boolean;
  from: string;
  if: string[];
}

interface Field {
  name: string;
  displayName: string;
  type: string;
  required?: boolean;
  validation?: Validation;
  editable?: boolean;
  valueFrom?: string;
  salesforce?: Salesforce;
  options?: string[];
  default?: string;
  visible?: boolean;
  dynamicOptions?: DynamicOptions;
  visibilityCondition?: VisibilityCondition;
  requirePastDate?: boolean;
  requireFutureDate?: boolean;
  multiLine?: boolean;
  additionalInformation?: string;
  multiSelect?: boolean;
  example?: string;
}

interface SupportConfig {
  description: string;
  fields: Field[];
}

interface RootConfig {
  [key: string]: SupportConfig;
}

interface DynamicFieldSelection {
  [key: string]: string;
}

interface FieldData {
  [key: string]: string;
}

interface FieldValidation {
  [key: string]: boolean;
}

interface FileUploadStatus {
  [key: string]: boolean;
}

interface SalesforceUploadResponse {
  id: string;
  success: boolean;
  errors: any[];
}

// eslint-disable-next-line @typescript-eslint/no-var-requires
const fields: RootConfig = require("../fields.json");

export default function NewTicket({ account, instance }: any) {
  const [dynamicFiledSelection, setDynamicFieldSelection] = React.useState<DynamicFieldSelection>({});
  const [fieldData, setFieldData] = React.useState<FieldData>({});
  const [fieldValid, setFieldValid] = React.useState<FieldValidation>({});
  const [selectedServiceTeam, setSelectedServiceTeam] = React.useState<string>();

  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [modalResult, setModalResult] = React.useState<"ticket" | "upload" | "done" | "error">("ticket");
  const [modalErrorMessage, setModalErrorMessage] = React.useState<string>();

  const [selectedFields, setSelectedFields] = React.useState<SupportConfig>();

  const [core, setCore] = React.useState<Core | undefined>(undefined);
  const [integrationHubUser, setIntegrationHubUser] = React.useState<any>();

  const [files, setFiles] = React.useState<File[]>([]);

  const [fileUploadDone, setFileUploadDone] = React.useState<FileUploadStatus>({});

  React.useEffect(() => {
    if (account && instance) {
      new Core().initialiseCore(instance, account).then(core => {
        setCore(core);
      });
    }
  }, [account, instance]);

  React.useEffect(() => {
    if (account && core) {
      core.invokeIntegrationHubRequest('GET', `/identity/users/${(account as AccountInfo).username}`).then(resp => {
        setIntegrationHubUser(resp.body);
      });
    }
  }, [account, core]);

  React.useEffect(() => {
    console.log(files)
  }, [files])

  React.useEffect(() => {
    // If fields are empty, attempt to assign data based on dynamic mapping.
    if (selectedServiceTeam) {
      let currentData = { ...fieldData };
      selectedFields?.fields.forEach(field => {
        if (field.dynamicOptions && field.salesforce?.fieldName) {
          if (field.dynamicOptions.mappingFrom in dynamicFiledSelection) {
            currentData = {
              ...currentData,
              [field.salesforce?.fieldName || field.name]: field.dynamicOptions.mapping[dynamicFiledSelection[field.dynamicOptions.mappingFrom]]?.length === 1 ? field.dynamicOptions.mapping[dynamicFiledSelection[field.dynamicOptions.mappingFrom]][0] : field.default || ""
            };
          }
        }
      });
      setFieldData(currentData);
    }
  }, [dynamicFiledSelection]);

  React.useEffect(() => {
    if (selectedServiceTeam) {
      setSelectedFields(fields[selectedServiceTeam]);

      setFieldData({
        ...fields[selectedServiceTeam].fields
          .filter(field => field.salesforce && field.salesforce.fieldName !== undefined)
          .filter(field => (!field.visibilityCondition?.from || field.visibilityCondition?.if.includes(dynamicFiledSelection[field.visibilityCondition?.from || ""])))
          .reduce((obj, item) => ({ ...obj, [item.salesforce?.fieldName || ""]: item.default || "" }), {}),
        ...{
          SuppliedEmail: account.username
        }
      })
    }
  }, [selectedServiceTeam]);

  React.useEffect(() => {
    if (modalOpen && files.length > 0 && Object.keys(fileUploadDone).length === files.length) {
      setTimeout(() => {
        setModalResult("done");
      }, 1000);
    }
  }, [fileUploadDone]);

  const uploadedFiles: FileUploadStatus = {};
  const updateFileUploadStatus = (name: string, status: boolean) => {
    uploadedFiles[name] = status;
    setFileUploadDone({
      ...fileUploadDone,
      ...uploadedFiles
    });
  }

  const uploadFileToSalesforce = (
    ticket: string,
    file: File
  ) => {
    const reader = new FileReader();

    reader.onloadend = async () => {
      if (reader.result instanceof ArrayBuffer) {
        const base64Data = Buffer.from(reader.result).toString('base64');
        const attachmentData = {
          ParentId: ticket,
          Name: file.name,
          Body: base64Data,
          ContentType: file.type || 'application/octet-stream',
        };

        const response = core?.invokeIntegrationHubRequest("POST", "/salesforce/ticket/attachments", attachmentData).then(response => {
          console.log(response);
          if (!(response?.statusCode === 200) && !(response?.statusCode === 201)) {

            const errorText = response?.body;
            console.error('File upload failed:', response?.statusCode, errorText);
            setTimeout(() => {
              updateFileUploadStatus(file.name, false);
            }, 1000 * files.indexOf(file));
          } else {
            setTimeout(() => {
              updateFileUploadStatus(file.name, true);
            }, 1000 * files.indexOf(file));
          }
        });
      }
    }
    reader.readAsArrayBuffer(file);
  }

  if (integrationHubUser) {
    return (
      <React.Fragment>
        <Modal open={modalOpen} onClose={(evt, reason) => {
          switch (reason) {
            default:
              setModalOpen(true);
              break;
          }
        }}>
          <ModalDialog>
            <Typography>{
              modalResult === "ticket" ? "Submitting Ticket" :
                modalResult === "upload" ? `Uploading Attachments ${Object.keys(fileUploadDone || {}).length}/${files.length}` :
                  modalResult === "done" ? "Done!" :
                    "Error"
            }</Typography>
            <Typography level='body-sm'>
              {
                modalResult === "ticket" ? "Please don't close or refresh this page." :
                  modalResult === "upload" ? "Please don't close or refresh this page." :
                    modalResult === "done" ? "You can now safely close this window." :
                      `There was an error submitting your ticket, please try again later. ${modalErrorMessage}`
              }
            </Typography>
            {(modalResult === "done" || modalResult === "error") &&
              <Button onClick={e => {
                location.reload()
              }} color={modalResult === "done" ? "success" : "danger"} >Ok</Button>}
            {
              (modalResult === "ticket" || modalResult === "upload") &&
              <LinearProgress determinate={!(modalResult === "ticket")}
                value={
                  modalResult === "ticket" ? undefined :
                    modalResult === "upload" ?
                      Object.keys(fileUploadDone || {}).length * 100 / files.length :
                      100
                }
              ></LinearProgress>
            }
          </ModalDialog>
        </Modal>
        <Typography key="title" level='title-lg'>Service Team</Typography>
        <Box
          key={0}
          sx={{
            padding: 0,
            display: 'grid',
            justifyContent: "space-between",
            gridTemplateColumns: {
              xs: '100%',
              sm: '50% 50%',
              lg: 'repeat(auto-fit, 32%) 32%',
            },

            mb: 2,
            '& > hr': {
              gridColumn: '1/-1',
            },
          }}
        >
          {
            Object.keys(fields).map(key => {
              return (
                <Box key={`${key}_selector`} sx={{
                  cursor: 'pointer'
                }} onClick={e => {
                  setFieldValid({});
                  setFieldData({});
                  setDynamicFieldSelection({});
                  setSelectedServiceTeam(key);
                }}>
                  <Card variant={selectedServiceTeam === key ? 'solid' : 'soft'} sx={{
                    flexGrow: 1
                  }} color={selectedServiceTeam === key ? 'primary' : undefined}>
                    <CardContent>
                      <Typography textColor="inherit" level="title-lg">{key}</Typography>
                      <Typography level="body-sm" textColor="inherit">{fields[key].description}</Typography>
                    </CardContent>
                  </Card>
                </Box>
              )
            })
          }
        </Box>
        <Card
          id={`${selectedServiceTeam}_form`}
          sx={{
            mt: 2
          }}>
          {
            selectedServiceTeam ?
              <Box sx={{ mb: 1 }}>
                <Typography level="title-md">Required Information</Typography>
                <Typography level="body-sm">
                  Please complete all the fields below to submit a new ticket. It's recommended to do so in order as some fields are dynamic.
                </Typography>
              </Box> : ""
          }
          <form
            onSubmit={(event) => {
              event.preventDefault();
              console.log(event);
              setModalOpen(true);
              if (core) {
                core.invokeIntegrationHubRequest('POST', `/salesforce/tickets`, {
                  fields: {
                    ...fieldData
                  }
                }).then(resp => {
                  // uploadFileToSalesforce

                  if (resp.statusCode === 200 || resp.statusCode === 201) {
                    if (files.length > 0) {
                      setModalResult("upload");
                      for (const file of files) {
                        uploadFileToSalesforce((resp.body as any).id || "", file);
                      }
                    } else {
                      setModalResult("done");
                      setModalOpen(true);
                    }
                  } else {
                    setModalErrorMessage((resp.body as any)[0].message);
                    setModalResult("error");
                    setModalOpen(true);
                  }
                }).catch(err => {
                  console.log(err);
                })
              }
            }}
          >
            <Box
              key={0}
              sx={{
                padding: 0,
                display: 'grid',
                justifyContent: "space-between",
                gridTemplateColumns: {
                  xs: '100%',
                  sm: '50% 50%',
                  lg: 'repeat(auto-fit, 32%) 32%',
                },

                mb: 2,
                '& > hr': {
                  gridColumn: '1/-1',
                },
              }}
            >
              {
                selectedServiceTeam ? selectedFields?.fields.map(field => {
                  return [
                    (!field.visibilityCondition?.from || field.visibilityCondition?.if.includes(dynamicFiledSelection[field.visibilityCondition?.from || ""]))
                    &&
                    <FormControl sx={{
                      display: field.visible === false ? 'none' : 'block',
                    }}>
                      <FormLabel sx={{
                        mt: 2
                      }} id={`${selectedServiceTeam}_${field.name}_fl`} key={`${selectedServiceTeam}_${field.name}_fl`}>
                        {field.displayName} {field.required ? <sup>*</sup> : ""}
                      </FormLabel>
                      {field.type !== "information" && (
                        field.options && field.options.length > 0 ? (
                          <Select
                            required={field.required} id={`${selectedServiceTeam}_${field.name}`}
                            key={`${selectedServiceTeam}_${field.name}`}
                            defaultValue={field.default}
                            onChange={e => {
                              console.log((e?.nativeEvent.target as any)?.innerText)
                              if (field.salesforce?.fieldName) {
                                setFieldData({ ...fieldData, [field.salesforce?.fieldName]: (e?.nativeEvent.target as any)?.innerText || undefined });
                              }
                            }}
                          >
                            {
                              field.options.map(option => {
                                return <Option value={option}
                                  onClick={
                                    e => {
                                      if (field.salesforce?.fieldName) {
                                        setFieldData({ ...fieldData, [field.salesforce?.fieldName]: option });
                                      }
                                      setDynamicFieldSelection({ ...dynamicFiledSelection, [field.name]: option });
                                    }
                                  }>{option}</Option>
                              })
                            }
                          </Select>
                        ) :
                          field.dynamicOptions ? (
                            field.dynamicOptions.mappingFrom in dynamicFiledSelection ?
                              <Select disabled={field.editable === false} required={field.required} id={`${selectedServiceTeam}_${field.name}`} key={`${selectedServiceTeam}_${field.name}`}
                                value={
                                  field.editable === false && dynamicFiledSelection[field.dynamicOptions.mappingFrom] &&
                                  field.dynamicOptions.mapping[dynamicFiledSelection[field.dynamicOptions.mappingFrom]] &&
                                  field.dynamicOptions.mapping[dynamicFiledSelection[field.dynamicOptions.mappingFrom]].length === 1 &&
                                  field.dynamicOptions.mapping[dynamicFiledSelection[field.dynamicOptions.mappingFrom]][0] || undefined
                                }
                                onChange={e => {
                                  console.log((e?.nativeEvent.target as any)?.innerText)
                                  if (field.salesforce?.fieldName) {
                                    setFieldData({ ...fieldData, [field.salesforce?.fieldName || field.name]: (e?.nativeEvent.target as any)?.innerText || undefined });
                                  }
                                }}
                              >
                                {
                                  dynamicFiledSelection[field.dynamicOptions.mappingFrom] && field.dynamicOptions.mapping[dynamicFiledSelection[field.dynamicOptions.mappingFrom]].map(option => {
                                    return (
                                      <Option
                                        value={option}
                                        onClick={
                                          e => {
                                            if (field.salesforce?.fieldName) {
                                              setFieldData({ ...fieldData, [field.salesforce?.fieldName || field.name]: option });
                                            }
                                            setDynamicFieldSelection({ ...dynamicFiledSelection, [field.name]: option });
                                          }
                                        }
                                      >{option}</Option>
                                    )
                                  })
                                }
                              </Select> : <Input required={(!field.visibilityCondition?.from || field.visibilityCondition?.if.includes(dynamicFiledSelection[field.visibilityCondition?.from || ""])) ? field.required : false} id={`${selectedServiceTeam}_${field.name}`} key={`${selectedServiceTeam}_${field.name}`} placeholder={`Please select an option from '${selectedFields.fields.find(f => f.name === field?.dynamicOptions?.mappingFrom)?.displayName}' first.`} disabled={true} />
                          ) :
                            field.type === "date" && (field.requireFutureDate || field.requirePastDate) ?
                              <Input id={`${selectedServiceTeam}_${field.name}`} key={`${selectedServiceTeam}_${field.name}`} slotProps={{
                                input: {
                                  min: field.requireFutureDate ? new Date().toISOString().split('T')[0] : undefined,
                                  max: field.requirePastDate ? new Date().toISOString().split('T')[0] : undefined,
                                }
                              }}
                                onChange={e => {
                                  console.log(e.target.value)
                                  if (field.salesforce?.fieldName) {
                                    setFieldData({ ...fieldData, [field.salesforce?.fieldName || field.name]: e.target.value });
                                  }
                                }}
                                type={field.type} required={(!field.visibilityCondition?.from || field.visibilityCondition?.if.includes(dynamicFiledSelection[field.visibilityCondition?.from || ""])) ? field.required : false} placeholder={""} disabled={field.editable === false} defaultValue={field.valueFrom ? integrationHubUser[field.valueFrom] : field.default ? field.default : ""} />
                              :
                              field.multiLine ?
                                <Textarea
                                  id={`${selectedServiceTeam}_${field.name}`}
                                  key={`${selectedServiceTeam}_${field.name}`}
                                  onChange={e => {
                                    if (field.salesforce?.fieldName) {
                                      setFieldData({ ...fieldData, [field.salesforce?.fieldName || field.name]: e.target.value });
                                    }
                                  }}
                                  required={(!field.visibilityCondition?.from || field.visibilityCondition?.if.includes(dynamicFiledSelection[field.visibilityCondition?.from || ""])) ? field.required : false} placeholder={""} disabled={field.editable === false} defaultValue={field.valueFrom ? integrationHubUser[field.valueFrom] : field.default ? field.default : ""}
                                >
                                </Textarea> :
                                <Input
                                  id={`${selectedServiceTeam}_${field.name}`}
                                  key={`${selectedServiceTeam}_${field.name}`}
                                  placeholder={field.example || ""}
                                  error={field.validation ? !fieldValid[field.salesforce?.fieldName || field.name] === false ? true : false : false}
                                  onChange={e => {
                                    if (field.validation?.pattern && (e.target.value || "").length > 0) {
                                      console.log(RegExp(field.validation.pattern).test(e.target.value))
                                      if (RegExp(field.validation.pattern).test(e.target.value)) {
                                        setFieldValid({
                                          ...fieldValid,
                                          [field.salesforce?.fieldName || field.name]: false
                                        })
                                      } else {
                                        setFieldValid({
                                          ...fieldValid,
                                          [field.salesforce?.fieldName || field.name]: true
                                        })
                                      }
                                    } else {
                                      setFieldValid({
                                        ...fieldValid,
                                        [field.salesforce?.fieldName || field.name]: false
                                      })
                                    }
                                    if (field.salesforce?.fieldName) {
                                      setFieldData({ ...fieldData, [field.salesforce?.fieldName || field.name]: e.target.value });
                                    }
                                  }}
                                  type={field.type} required={(!field.visibilityCondition?.from || field.visibilityCondition?.if.includes(dynamicFiledSelection[field.visibilityCondition?.from || ""])) ? field.required : false} disabled={field.editable === false} defaultValue={field.valueFrom ? integrationHubUser[field.valueFrom] : field.default ? field.default : ""} />
                      )
                      }
                      {field.additionalInformation ? <Typography mt={1} level='body-xs' startDecorator={<InfoIcon color='primary'></InfoIcon>}>{field.additionalInformation}</Typography> :
                        <Typography mt={3} level='body-sm'> </Typography>
                      }
                    </FormControl>
                  ]
                }) : <Box>

                  <Typography startDecorator={<InfoIcon></InfoIcon>}>Please select a service team to begin.</Typography>
                </Box>

              }
            </Box>
            {
              selectedServiceTeam ? <Stack spacing={2} sx={{ my: 1 }}>
                <DropZone files={files} setFiles={setFiles} />
              </Stack> : ""
            }
            {
              selectedServiceTeam ?
                <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
                  <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
                    {
                      selectedServiceTeam ?
                        <Button onClick={e => {
                          console.log(fieldData);
                        }}
                          type='submit'
                          startDecorator={<SendIcon></SendIcon>}>
                          Submit
                        </Button> : ""
                    }
                  </CardActions>
                </CardOverflow> : ""
            }
          </form>
        </Card>
      </React.Fragment >
    );
  } else {
    return (
      <React.Fragment>
        <Typography>Please wait while we check your account...</Typography>
        <Box><LinearProgress></LinearProgress></Box>
      </React.Fragment>
    )
  }
}
