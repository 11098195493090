import * as React from 'react';
import Card, { CardProps } from '@mui/joy/Card';
import Link from '@mui/joy/Link';
import Typography from '@mui/joy/Typography';
import AspectRatio from '@mui/joy/AspectRatio';

import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded';
import { Box, Chip } from '@mui/joy';
import { start } from 'repl';
import InsertDriveFileRoundedIcon from '@mui/icons-material/InsertDriveFileRounded';

export default function DropZone(props: CardProps & { files: File[], setFiles: React.Dispatch<React.SetStateAction<File[]>>, icon?: React.ReactElement<any> },
  
) {
  const { icon, sx, setFiles, files, ...other } = props;  

  return (
    <Box
      onDrop={e => {
        e.preventDefault();
        setFiles([
          ...files,
          ...Array.from(e.dataTransfer.files)
        ]);
      }}
      onDragOver={e => {
        e.preventDefault();
      }}
    >
      <Card
        variant="soft"
        {...other}
        sx={[
          {
            borderRadius: 'sm',
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            alignItems: 'center',
            px: 3,
            flexGrow: 1,
            boxShadow: 'none',
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        <AspectRatio
          ratio="1"
          variant="solid"
          color="primary"
          sx={{ minWidth: 32, borderRadius: '50%', '--Icon-fontSize': '16px' }}
        >
          <div>{icon ?? <FileUploadRoundedIcon />}</div>
        </AspectRatio>
        <Typography level="body-sm" sx={{ textAlign: 'center' }}>
          Need to add an attachment? <br></br>
          <Link onClick={e => {
            // Show upload file picker
            e.preventDefault();
            const input = document.createElement('input');
            input.type = 'file';
            input.multiple = true;
            input.click();
            input.onchange = (e: any) => {
              setFiles([
                ...files || [],
                ...Array.from(e.target.files) as any
              ]);
              console.log(e.target.files);
            }

          }}
            component="button" overlay>
            Click to upload
          </Link>{' '}
          or drag and drop.
        </Typography>
        {
          files.length > 0 ?
            files.map(file => {
              return (
                <Chip
                  sx={{
                    py: 0.5,
                    px: 2
                  }}
                  key={file.name}
                  variant="solid"
                  color="primary"
                  size="sm"
                  startDecorator={<InsertDriveFileRoundedIcon />}
                >
                  {file.name}
                </Chip>
              )
            }) : ""
        }
      </Card>
    </Box>
  );
}